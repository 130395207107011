import { asHostBy } from './host-by';
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type APIS = {
    readonly AUTH_RETRIEVE: string;
    readonly AUTH_SIGNUP: string;
    readonly AUTH_TRUST_SIGNIN: string;
    readonly AUTH_SIGNIN: string;
    readonly AUTH_FORGET_PWD: string;
    readonly AUTH_SIGNOUT: string;
    readonly AUTH_REGISTER: string;
    readonly AVATAR_UPLOAD: string;
    readonly CHANGE_PWD: string;
    readonly DIRECT_ASK: string;

    
    
    
    
    
    
    
    

    readonly TENANT_REGISTER_APPLY: string;
    readonly TENANT_REGISTER_LIST: string;
    readonly TENANT_REGISTER_LOAD: string;
    readonly TENANT_REGISTER_APPROVE: string;
    readonly TENANT_REGISTER_REJECT: string;

    readonly TENANT_CURRENT: string;
    readonly TENANT_CREATE: string;
    readonly TENANT_UPDATE: string;
    readonly TENANT_PROFILE_UPDATE: string;
    readonly TENANT_LOAD: string;
    readonly TENANT_LIST: string;
    readonly TENANT_LIST_ALL: string;

    readonly APIKEY_LIST: string;
    readonly APIKEY_DELETE: string;
    readonly APIKEY_DISABLE: string;
    readonly APIKEY_ENABLED: string;
    readonly APIKEY_REQUEST: string;

    readonly ORGAN_CURRENT: string;
    readonly ORGAN_CREATE: string;
    readonly ORGAN_UPDATE: string;
    readonly ORGAN_LOAD: string;
    readonly ORGAN_LIST: string;
    readonly ORGAN_LIST_FOR_CREATE: string;
    readonly ORGAN_LIST_FOR_EDIT: string;
    readonly ORGAN_UPLOAD_SYNC: string;

    readonly ACCOUNT_CURRENT: string;
    readonly ACCOUNT_CREATE: string;
    readonly ACCOUNT_UPDATE: string;
    readonly ACCOUNT_LIST: string;
    readonly ACCOUNT_LOAD: string;
    readonly ACCOUNT_CURRENT_BY_COMPANY_CODE: string;
    readonly ACCOUNT_SECURE_INFO_LOAD: string;
    readonly ACCOUNT_UPLOAD_SYNC: string;
    readonly ACCOUNT_MENU_BY_TENANT_LOAD: string;
    readonly ACCOUNT_MENU_BY_TENANT_UPDATE: string;
    readonly ACCOUNT_CHECK_SALES_AUTH: string;
    readonly ACCOUNT_CHECK_ACCOUNT_EXIST: string;
    readonly ACCOUNT_FIND_HQ_ACCOUNT_CODE: string;
    readonly ACCOUNT_MAPPING_CURRENT: string;
    readonly ACCOUNT_SALES_CHECK: string;

    readonly PROBATION_SERVICE_LIST: string;
    readonly PROBATION_SERVICE_LOAD: string;
    readonly PROBATION_SERVICE_UPDATE: string;
    readonly PROBATION_SERVICE_APPLY: string;

    readonly COURSE_LIST: string;
    readonly COURSE_CREATE: string;
    readonly COURSE_UPDATE: string;

    readonly VENDOR_LIST: string;
    readonly VENDOR_AVAILABLE_LIST: string;
    readonly VENDOR_LIST_CHANNEL: string;
    readonly PRODUCT_LIST: string;
    readonly PRODUCT_LIST_BY_VENDOR: string;
    readonly PRODUCT_LOAD: string;
    readonly PRODUCT_LOAD_BY_CODE: string;
    readonly PRODUCT_CREATE: string;
    readonly PRODUCT_UPDATE: string;

    readonly PRODUCT_TAG_LIST: string;
    readonly PRODUCT_TAG_LIST_CATEGORY: string;
    readonly PRODUCT_TAG_LIST_HIERARCHICAL: string;
    readonly PRODUCT_TAG_LOAD: string;
    readonly PRODUCT_TAG_DELETE: string;
    readonly PRODUCT_TAG_SAVE: string;
    readonly PRODUCT_TAG_JOINT_SAVE: string;

    readonly AGREEMENT_LIST: string;
    readonly AGREEMENT_LIST_BY_TENANT_AND_VENDOR: string;
    readonly AGREEMENT_LOAD: string;
    readonly AGREEMENT_CREATE: string;
    readonly AGREEMENT_UPDATE: string;
    readonly AGREEMENT_CLONE: string;

    readonly DASHBOARD_LOAD: string;
    readonly DASHBOARD_GENERATE: string;

    readonly SMS_QUERY: string;
    readonly SMS_BLACK_LIST_QUERY: string;
    readonly ACCOUNT_SYNC_QUERY: string;

    readonly MESSAGE_CENTER_LIST: string;
    readonly MESSAGE_CENTER_CREATE: string;

    readonly POLICY_DESK_LIST: string;
    readonly POLICY_URL_LIST: string;
    readonly POLICY_REPORT_GENERATE: string;
    readonly POLICY_LOAD: string;
    readonly POLICY_FIND_BY_POLICY_NO: string;
    readonly POLICY_FIND_BY_QUOTE_NO: string;
    readonly POLICY_FIND_MINI_BY_QUOTE_NO: string;
    readonly POLICY_FIND_HISTORY_BY_QUOTE_NO: string;
    readonly POLICY_CREATE_V2: string;
    readonly POLICY_CREATE_V1: string;
    readonly POLICY_UPDATE_V3: string;
    readonly POLICY_UPDATE_V2: string;
    readonly POLICY_UPDATE_V1: string;
    readonly POLICY_CALCULATE_V2: string;
    readonly POLICY_CALCULATE_ADVICE_V2: string;
    readonly POLICY_ILLUSTRATE_V2: string;
    readonly POLICY_CALCULATE_V1: string;
    readonly POLICY_APPLY_V2: string;
    readonly POLICY_UPLOAD_V2: string;
    readonly POLICY_APPLY_V1: string;
    readonly POLICY_UNDERWRITE_V2: string;
    readonly POLICY_UNDERWRITE_V1: string;
    readonly POLICY_AUTH_QUERY_V2: string;
    readonly POLICY_AUTH_APPLY_V2: string;
    readonly POLICY_AUTH_CONFIRM_V2: string;
    readonly POLICY_AUTH_REAPPLY_V2: string;
    readonly POLICY_SUBMIT_ORDER_V2: string;
    readonly POLICY_SUBMIT_ORDER_2_V2: string;
    readonly POLICY_SUBMIT_POLICY_V2: string;
    readonly POLICY_SUBMIT_POLICY_ASYNC_V2: string;
    readonly POLICY_ISSUE_V2: string;
    readonly POLICY_ISSUE_V1: string;
    readonly POLICY_WITHDRAW_V2: string;
    readonly POLICY_REQUOTE_V2: string;
    readonly POLICY_RECEIPT_V2: string;
    readonly ABANDON_SIGNATURE_IDS: string;
    readonly LOAD_CA_SIGNATURES: string;
    readonly CREATE_SIGNATURE: string;
    readonly UPDATE_SIGNATURE: string;
    readonly REGISTER_SIGNATURE: string;
    readonly CHECK_SIGNATURE: string;
    readonly SEND_VALIDATION_CODE: string;
    readonly SEND_COMMON_SMS_CODE: string;
    readonly SEND_SMS_VALIDATE_CODE: string;
    readonly SEND_VENDOR_COMMON_SMS_CODE: string;
    readonly VALIDATE_VALIDATION_CODE: string;
    readonly VALIDATE_COMMON_SMS_CODE: string;
    readonly VALIDATE_COMPLETE_SIGNATURE: string;
    readonly CONFIRM_SIGNATURE: string;
    readonly CHECK_RECEIPT: string;
    readonly DOC_GENERATE: string;
    readonly SIGN_PDF: string;
    readonly SIGN_PDF_V2: string;
    readonly POLICY_IMAGE_UPLOAD: string;
    readonly POLICY_IMAGE_UPLOAD_V2: string;
    readonly POLICY_VIDEO_UPLOAD: string;
    readonly POLICY_DOCUMENT_UPLOAD: string;
    readonly POLICY_COLLECT_PREMIUM: string;
    readonly POLICY_SYNC: string;
    readonly CUSTOMER_LIST_CONDITION: string;
    readonly CUSTOMER_LOAD: string;
    readonly POLICY_NOTIFY_LOG_LIST: string;
    readonly POLICY_NOTIFY_LOG_LOAD: string;
    readonly POLICY_NOTIFY_PROCESS_LOG_RETRY: string;
    readonly POLICY_NOTIFY_PROCESS_LOG_LOAD: string;
    readonly POLICY_GENERATE_QUOTE_NO: string;
    readonly POLICY_CHECK_INSURED: string;
    readonly QUERY_AGENT_INFO: string;
    readonly AIXIN_WX_SIGN: string;
    readonly POLICY_PROBLEM_LIST: string;
    readonly POLICY_PROBLEM_SOLVE: string,
    readonly POLICY_ADDRESS_VALIDITY_V2: string;
    readonly UPLOAD_UPDATE_HISTORY: string;
    readonly UPDATE_STATUS_AND_HISTORY: string;
    readonly CHECK_AGENT_QUALIFICATION: string;
    readonly POLICY_CHECK: string;

    readonly STATISTICS_REPORT_GENERATE: string;

    readonly MOBILE_HOME_PRODUCT_LIST: string;
    readonly PRODUCT_CATEGORY_LIST: string;
    readonly PRODUCT_LIST_BY_KEYWORD: string;
    readonly PRODUCT_LIST_BY_VENDOR_IDS: string;
    readonly PRODUCT_LIST_BY_VENDOR_CODE: string;
    readonly PRODUCT_LIST_BY_1ST_TAGS: string;
    readonly PRODUCT_LIST_BY_1ST_TAG_NAMES: string;
    readonly PRODUCT_LIST_BY_3RD_TAGS: string;
    readonly PRODUCT_LIST_BY_ADVICE: string;
    readonly PRODUCT_LOAD_ADDRESS: string;

    readonly MY_POLICY_LIST_BY_STATUS: string;
    readonly MY_POLICY_LIST_BY_CRITERIA: string;
    readonly MY_CUSTOMER_LIST: string;
    readonly MY_CUSTOMER_LIST_YDL: string;
    readonly SALES_AGENT_LIST: string;
    readonly POLICY_LIST_BY_MY_CUSTOMER: string;
    readonly LOAD_CUSTOMER_BY_KEYS: string;
    readonly FAVORITE_PRODUCT_LIST: string;
    readonly FAVORITE_PRODUCTS_UNLIKE: string;
    readonly FAVORITE_PRODUCT_LIKE: string;
    readonly FAVORITE_PRODUCT_UNLIKE: string;
    readonly FAVORITE_PRODUCT_IS_LIKED: string;

    readonly CHECK_AGREEMENT: string;
    readonly CHECK_AGREEMENT_NO_LOGIN: string;

    readonly WECHAT_SIGNATURE: string;
    readonly WECHAT_TOC_REGISTER: string;

    readonly GLUTTON_ASK_TOKEN: string;
    readonly STS_ASK_TOKEN: string;

    readonly POLICY_PAYMENT_YIAN: string;
    readonly POLICY_PAYMENT_FOSUN: string;
    readonly POLICY_PAYMENT_FOSUN_DT: string;
    readonly POLICY_PAYMENT_ZHONGAN: string;
    readonly POLICY_PAYMENT_DINGCHENG: string;
    readonly POLICY_DECLARATION_ZHONGAN: string;
    readonly POLICY_PAYMENT_YANGG: string;
    readonly POLICY_PAYMENT_QUERY: string;
    readonly POLICY_PAYMENT_APPLY: string;
    readonly POLICY_PAYMENT_CONFIRM: string;
    readonly POLICY_PAYMENT_KUNLUN: string;
    readonly POLICY_PAYMENT_STARRCHINA: string;
    readonly POLICY_PAYMENT_HEXIE: string;


    readonly STATEMENT_AGREEMENT_LIST: string;
    readonly STATEMENT_AGREEMENT_LIST_BY_TENANT_AND_VENDOR: string;
    readonly STATEMENT_AGREEMENT_LOAD: string;
    readonly STATEMENT_AGREEMENT_DELETE: string;
    readonly STATEMENT_AGREEMENT_CREATE_OR_UPDATE: string;
    readonly STATEMENT_AGREEMENT_BY_PRODUCT_ID: string;
    readonly STATEMENT_COMMISSION_BY_PRODUCT_ID: string;
    readonly STATEMENT_COMMISSION_CREATE_OR_UPDATE: string;
    readonly STATEMENT_BATCH_EXECUTE_BILL: string;
    readonly STATEMENT_RESULT_BY_BATCH_ID: string;
    readonly STATEMENT_RESULT_LIST: string;
    readonly STATEMENT_BATCH_QUERY_ORGAN: string;


    readonly OCR_IDCARD_FRONT: string;
    readonly OCR_BANKCARD_FRONT: string;
    readonly OCR_HANDWRITING: string;
    readonly OCR_OSS_RECOGNIZE: string;
    /** 保司OCR接口 */
    readonly OCR_IDCARD_COMPANY: string;

    readonly MALL_PRODUCT_LIST_HOME: string;
    readonly MALL_PRODUCT_LIST_BY_KEYWORD: string;
    readonly MALL_PRODUCT_LIST_BY_PROMOTION: string;
    readonly MALL_PRODUCT_LIST_BY_TAG: string;
    readonly MALL_PRODUCT_LIST_BY_TAGS: string;
    readonly MALL_PRODUCT_CHECK_AGREEMENT: string;
    readonly MY_MALL_POLICY_LIST_BY_STATUS: string;
    readonly PRODUCT_LOAD_BY_CODE_NO_LOGIN: string;
    readonly PRODUCT_LOAD_BY_CODE_NO_LOGIN_WITH_TENANTCODE: string;
    readonly MALL_CUSTOMER_SIGNIN: string;
    readonly MALL_CUSTOMER_SIGNIN_SMS_CODE: string;
    readonly MY_MALL_CUSTOMER_POLICY_LIST_BY_STATUS: string;
    readonly MY_MALL_2C_POLICY_LIST_BY_STATUS: string;
    readonly MY_MALL_CHANNEL_POLICY_LIST_BY_STATUS: string;

	readonly ID_LIVING_DETECT: string;
    readonly POLICE_VERIFY_WITHOUT_FACE: string;
    readonly IDENTITY_NAME_FACE_VERIFY: string;

    readonly MALL_POLICY_SHARE_SMS_CODE: string;
    readonly MALL_POLICY_SHARE_SMS_CODE_CHECK: string;

    readonly WEIXIN_GET_OPEN_ID: string;
    readonly LOAD_ACCOUNT_BY_OPEN_ID: string;
    readonly LOAD_WECHATACCOUNT_BY_ACCOUNTNAME: string;
    readonly TOC_UPDATE_ACCOUNT_INFO: string;
    readonly TOC_UNBOUND_WX_ACCOUNT: string;

    readonly IDENTITY_CERTIFICATION_LIST: string;
    readonly IDENTITY_CERTIFICATION_EXCEL_EXPORT: string;

    readonly BASEINFO_AREA: string;
    readonly BASEINFO_JOB: string;
    readonly BASEINFO_IDTYPE: string;
    readonly BASEINFO_MARRIAGE: string;
    readonly BASEINFO_NATIONALITY: string;
    readonly BASEINFO_BANK: string;
    readonly BASEINFO_EDUCATION: string;
    readonly BASEINFO_COUNTRY: string;

    readonly BASEINFO_UI_DRIVE_PARAMS: string;

    readonly MALL_APPOINT_CREATE_PROSPECT: string;
    readonly AUDIT_QUERY_LIST: string;
    readonly AUDIT_CREATE: string;
    readonly QUERY_AUDIT_RESULT: string;
    readonly AUDIT_APPLY: string;

    readonly IMAGE_AUDIT_INFO_BY_ID: string;
    readonly IMAGE_AUDIT_EXAMINE: string;
    readonly AUDIT_DELETE: string;

    readonly QUERY_COLLECT_STATUS: string;
    readonly CHANGE_BANK_INFO: string;
    readonly CHANGE_QUERY_SOLVENCY: string;
    readonly CHANGE_QUERY_RECEIPT: string;
    readonly CHANGE_QUESTIONNAIRE: string;

    readonly FACE_RECOGNIZE_RESULT: string;
    readonly FACE_RECOGNIZE_RESETALL: string;

    readonly FXBDX_POLICY_QUERY_OMNIPOTENT_ACCOUNT: string;

    readonly VERIFY_PERSONAL_INFO: string;
    readonly POLICY_DECLARATION_JDALLIANZ: string;

    readonly DR_GET_QRCODE_IMG: string;
    readonly DR_CHECK_NEED: string;
    readonly DR_INVALID: string;
    readonly DR_QUERY_STATUS: string;
    readonly DR_SAVE_DOCS: string;
    readonly POLICY_VALIDATE_DATA: string;
    readonly OFFLINE_PAY: string;
    readonly BASE64FACEIMAGE: string;

	readonly COMPANY_DOUBLE_RECORD: string;
	// 预览与生成CA接口列表
	readonly GET_PREVIEW_HTML: string;
	readonly GET_PREVIEW_PDF: string;
    readonly GET_PREVIEW_PDFURL: string;
    readonly GET_PREVIEW_PDFURL_AGILITY: string;
    readonly GET_PREVIEW_PDFURL_AGILITY_PDF: string;
    readonly SIGN_PDF_AGILITY: string;
    readonly SIGN_PDF_AGILITY_PDF: string;
    readonly SIGN_PDF_AGILITY_BATCH: string;
	readonly CA_SIGN: string;
    readonly CA_SIGNINBATCH: string;

	readonly GET_PAYURL: string;
	readonly GET_RECEIPT_URL: string;
    readonly GET_ISSUES_QUERYLIST: string;//查询
    readonly REPLY_ISSUE_ITEM: string;//回复

    readonly RE_INSURE_POLICY: string;
    readonly GET_BAR_CODE_IMAGEBASE64: string;

    readonly LIAN_CHECKOUT_NEED_DOUBLERECORD: string;
    readonly LIAN_GENERATE_PDF: string;
    readonly ACCOUNT_PROFILE: string;
    readonly DR_DOCUMENT_SIGN: string;
    readonly ELDERLY_CARE_QUESTIONNAIRE_QUERY: string;
    readonly POLICY_RECEIPT_KUNLUN: string;
    readonly FACE_CHECK_INSURED: string;
    readonly CANCEL_POLICY_NO: string;
    readonly CHANGE_TO_OFFLINEPAY: string;
    readonly GIVE_UP_POLICY: string;

    readonly DOWNLOAD_EPOLICY: string;
    readonly POLICY_QUERY_ISSUES: string;
    readonly POLICY_ISSUE_UPDATE: string;
    readonly POLICY_ISSUE_LOAD: string;
    readonly POLICY_ISSUE_LOAD_BY_NO: string;
    readonly POLICY_ISSUE_REPLY: string;
    readonly LIAN_DOUBLE_RECORD_UPLOAD_DOCS: string;
    readonly LIAN_DOUBLE_RECORD_QUERY_QRCODE: string;

    readonly BJLIFE_DECLA_APPLY: string;//北京人寿只能核保
    readonly BJLIFE_QUESTION: string;
    readonly AIXIN_QUESTION: string;
    readonly CANCEL_POLICY: string;
    readonly CHANGC_RELATED_POLICY: string;
    readonly POLICY_COMPANY_DOUBLE_RECORD: string;
    readonly ZHONGH_GET_SIMILAR_CUSTOMER: string;

    //新版token获取 用于分享链生成
    readonly ACCESS_TOKEN: string;


    /** 上报操作记录 */
	readonly RECORD_LOG_NOTIFY: string;

	readonly DR_START_MISSION: string;
	readonly DR_PUSH_SIGN_STATUS: string;

    // 壹心科技
    readonly GET_YIXIN_ACCESS_TOKEN: string;

    /** 查询可关联保单 */
    readonly QUERY_ASSOCIATE_POLICY: string;
    /** 关联保单 */
    readonly ASSOCIATE_POLICY: string;
    readonly UPLOAD_AND_COLLECT_POLICY: string;
    readonly SEND_VALIDATE_CODE: string;

     /**投保前状态检查*/
     readonly PRE_SUBMIT_CHECK: string;

     /** documents接口 */
	readonly DOC_SAVE_DOCUMENT_LIST: string;
	readonly DOC_SAVE_OR_UPDATE_DOCUMENT: string;
	readonly DOC_DEL_DOCUMENT: string;
	readonly DOC_DEL_DOCUMENT_BY_ROLE: string;
	readonly DOC_DEL_DOCUMENT_BY_IDS: string;
	readonly DOC_CLEAR_SIGN_DOCUMENTS: string;
	readonly DOC_CLEAR_SIGN_DOCUMENTS_BY_DOCTYPES: string;
	readonly DOC_UPDATE_DOCS_HOLDER: string; // 判断投保人身份证或银行卡是否一致，不一致时删除
	readonly DOC_UPDATE_DOCS_INSURED_AND_BENEFICARY: string; // 判断身份证是否一致，不一致时删除
	readonly DOC_UPDATE_BENEFICIARY_DOCUMENTS: string;
	// readonly DOC_GET_DOCUMENTS_LIST_QUOTENO: string;
	// readonly DOC_DOCUMENTS_LIST_QUOTENO_DOCTYPE: string;
	// readonly DOC_DOCUMENTS_LIST_QUOTENO_DOCTYPES: string;
	// readonly DOC_DOCUMENTS_LIST_QUOTENO_ROLETYPE: string;
    /** 压缩oss图片接口 */
    readonly DOC_COMPRESSION_DOCUMENT: string;

    /** 昆仑接口 */
    readonly KUNLUN_QUERY_RISK_GRADE: string;
    readonly KUNLUN_QUERY_RISK_QUESTIONNAIRE: string;
    readonly KUNLUN_ASSESS_RISK_GRADE: string;

    /** 信泰H5接口 */
    readonly XINT_H5_GETURL: string;
    readonly XINT_H5_PAYMENT: string;
    readonly XINT_H5_ISSUEDETAIL: string;
    readonly XINT_H5_WITHDRAW: string;
    readonly XINT_H5_RECEIPT: string;
    /** 计算重庆问卷 */
    readonly CALCULATE_QUESTIONNAIRE: string;
    readonly SEND_EMAIL_EPOLICY: string;
    readonly INVALIDATE_BY_SHARE_RECORD_NO: string;
    readonly GET_AGENT_DECLARATIONS: string;

    readonly ZHONGYOU_QUERY_RISK_QUESTIONNAIRE: string;

    readonly EMAIL_EPOLICY: string;
    readonly GET_TENANTNAME_BY_TENANTCODE: string;

    readonly FAMILY_MEMBER_LIST_ACCOUNT_ID: string;

    readonly LOAD_ENDORESMENTLIST_BY_POLICY:string;
    
	readonly LOAD_SIGNATURE: string;
    
	readonly MEJ_VERIFY_SMS: string;
	readonly LONG_TO_SHORT_URL: string;

    readonly SURRENDER_CALCULATE: string;
    readonly SURRENDER_CREATE: string;
    readonly SURRENDER_SUBMIT: string;
    readonly SEND_SMS_VALIDATE_CODE_TYPE: string;
    readonly FUDSM_QUERY_RISK_QUESTIONNAIRE: string;
    readonly YANGG_QUERY_RISK_GRADE:string;

    readonly POLICY_CALCULATE_QUOTE_V2: string;//报价计算
    readonly QUERY_RISK_GRADE:string;
    readonly POLICY_QUERY_DOUBLE_RECORD_STATUS: string;

};
/**
 * 所有API路由声明
 */
export default {
    AUTH_RETRIEVE: '/rest/v1/auth/retrieve',
    AUTH_SIGNUP: '/rest/v1/auth/signup',
    AUTH_TRUST_SIGNIN: '/rest/v1/auth/trustSignin',
    AUTH_SIGNIN: '/rest/v1/auth/signin',
    AUTH_FORGET_PWD: '/rest/v1/auth/forgetp/',
    AUTH_SIGNOUT: '/rest/v1/auth/signout',//登出
    AUTH_REGISTER: '/rest/v1/auth/register',
    CHANGE_PWD: '/rest/v1/auth/changep',
    AVATAR_UPLOAD: '/rest/v1/account/avatar',
    DIRECT_ASK: '/rest/v1/tenant/direct/ask/',

    
    
    
    
    
    
    
    

    TENANT_REGISTER_APPLY: '/rest/v1/tenant/register/create',
    TENANT_REGISTER_LIST: '/rest/v1/tenant/register/list/criteria',
    TENANT_REGISTER_LOAD: '/rest/v1/tenant/register/load/',
    TENANT_REGISTER_APPROVE: '/rest/v1/tenant/register/approve',
    TENANT_REGISTER_REJECT: '/rest/v1/tenant/register/reject',

    TENANT_CURRENT: '/rest/v1/tenant/current',
    TENANT_CREATE: '/rest/v1/tenant/create',
    TENANT_UPDATE: '/rest/v1/tenant/update',
    TENANT_PROFILE_UPDATE: '/rest/v1/account/menus/tenant/profile/update',
    TENANT_LOAD: '/rest/v1/tenant/load/',
    TENANT_LIST: '/rest/v1/tenant/list/criteria',
    TENANT_LIST_ALL: '/rest/v1/tenant/list/all',

    APIKEY_LIST: '/rest/v1/account/api-key/list',
    APIKEY_DELETE: '/rest/v1/account/api-key/delete',
    APIKEY_DISABLE: '/rest/v1/account/api-key/disable',
    APIKEY_ENABLED: '/rest/v1/account/api-key/enable',
    APIKEY_REQUEST: '/rest/v1/account/api-key/sign',

    ORGAN_CURRENT: '/rest/v1/organ/current',
    ORGAN_CREATE: '/rest/v1/organ/create',
    ORGAN_UPDATE: '/rest/v1/organ/update',
    ORGAN_LOAD: '/rest/v1/organ/load/',
    ORGAN_LIST: '/rest/v1/organ/list/criteria',
    ORGAN_LIST_FOR_CREATE: '/rest/v1/organ/list/create/',
    ORGAN_LIST_FOR_EDIT: '/rest/v1/organ/list/edit/',
    ORGAN_UPLOAD_SYNC: '/rest/v1/organ/uploadSync',

    ACCOUNT_CURRENT: '/rest/v1/account/current',
    ACCOUNT_CREATE: '/rest/v1/account/create',
    ACCOUNT_UPDATE: '/rest/v1/account/update',
    ACCOUNT_LIST: '/rest/v1/account/list/criteria',
    ACCOUNT_LOAD: '/rest/v1/account/load/',
    ACCOUNT_CURRENT_BY_COMPANY_CODE: '/rest/v1/account/profile',
    ACCOUNT_SECURE_INFO_LOAD: '/rest/v1/account/secure/',
    ACCOUNT_UPLOAD_SYNC: '/rest/v1/account/uploadSync',
    ACCOUNT_MENU_BY_TENANT_LOAD: '/rest/v1/account/menus/tenant/load/',
    ACCOUNT_MENU_BY_TENANT_UPDATE: '/rest/v1/account/menus/tenant/update',
    ACCOUNT_CHECK_SALES_AUTH: '/rest/v1/account/checkAgentSaleAuthority/',
    ACCOUNT_CHECK_ACCOUNT_EXIST: '/rest/v1/account/checkAccountExist/',
    ACCOUNT_FIND_HQ_ACCOUNT_CODE: '/rest/v1/account/findHQAccountCode/',
    ACCOUNT_SALES_CHECK: '/rest/v1/account/sales/check',

    PROBATION_SERVICE_LIST: '/rest/v1/servicing/probation/list',
    PROBATION_SERVICE_LOAD: '/rest/v1/servicing/probation/load/',
    PROBATION_SERVICE_UPDATE: '/rest/v1/servicing/probation/update',
    PROBATION_SERVICE_APPLY: '/rest/v1/servicing/probation/apply',

    COURSE_LIST: '/rest/v1/course/list/criteria',
    COURSE_CREATE: '/rest/v1/course/create',
    COURSE_UPDATE: '/rest/v1/course/update',

    VENDOR_LIST: '/rest/v1/product/vendor/list',
    VENDOR_AVAILABLE_LIST: '/rest/v1/product/vendor/list/available',
    VENDOR_LIST_CHANNEL: '/rest/v1/dashboard/list/endSalesChannel',
    PRODUCT_LIST: '/rest/v1/product/list/criteria',
    PRODUCT_LIST_BY_VENDOR: '/rest/v1/product/list/vendors/assignTenant',
    PRODUCT_LOAD: '/rest/v1/product/load/',
    PRODUCT_LOAD_BY_CODE: '/rest/v1/product/loadProduct/',
    PRODUCT_CREATE: '/rest/v1/product/create',
    PRODUCT_UPDATE: '/rest/v1/product/update',

    PRODUCT_TAG_LIST: '/rest/v1/product/tag/list/criteria',
    PRODUCT_TAG_LIST_CATEGORY: '/rest/v1/product/tag/list/system/category',
    PRODUCT_TAG_LIST_HIERARCHICAL: '/rest/v1/product/tag/list/system/hierarchical',
    PRODUCT_TAG_LOAD: '/rest/v1/product/tag/load/',
    PRODUCT_TAG_DELETE: '/rest/v1/product/tag/delete/',
    PRODUCT_TAG_SAVE: '/rest/v1/product/tag/save',
    PRODUCT_TAG_JOINT_SAVE: '/rest/v1/product/tag/save/joints/',

    AGREEMENT_LIST: '/rest/v1/agree/list/criteria',
    AGREEMENT_LOAD: '/rest/v1/agree/load/',
    AGREEMENT_CREATE: '/rest/v1/agree/create',
    AGREEMENT_UPDATE: '/rest/v1/agree/update',
    AGREEMENT_CLONE: '/rest/v1/agree/clone/',

    DASHBOARD_LOAD: '/rest/v1/dashboard/loadShowData',
    DASHBOARD_GENERATE: '/rest/v1/dashboard/generate',

    SMS_QUERY: '/rest/v1/sms/query',
    SMS_BLACK_LIST_QUERY: '/rest/v1/sms/blackList/query',
    ACCOUNT_SYNC_QUERY: '/rest/v1/account/sync/query',

    MESSAGE_CENTER_LIST: '/rest/v1/message/list/criteria',
    MESSAGE_CENTER_CREATE: '/rest/v1/message/create',

    POLICY_DESK_LIST: '/rest/v1/policy/list/desk/criteria',
    POLICY_URL_LIST: '/rest/v1/policy/url/list',

    POLICY_REPORT_GENERATE: '/rest/v1/policy/report/generate',
    POLICY_LOAD: '/rest/v2/policy/find/id/',
    POLICY_FIND_BY_POLICY_NO: '/rest/v2/policy/findBypolicyNo/',
    POLICY_FIND_BY_QUOTE_NO: '/rest/v2/policy/find/quoteNo/',
    POLICY_FIND_HISTORY_BY_QUOTE_NO: '/rest/v2/policy/find/historyPolicy/quoteNo/',
    POLICY_FIND_MINI_BY_QUOTE_NO: '/rest/v2/policy/find/miniPolicy/quoteNo/',
    POLICY_CREATE_V2: '/rest/v2/policy/create',
    POLICY_CREATE_V1: '/rest/v1/policy/create',
    
    
	POLICY_UPDATE_V3: '/rest/v2/policy/update/safe',
    POLICY_UPDATE_V2: '/rest/v2/policy/update',
    POLICY_UPDATE_V1: '/rest/v1/policy/update',
    POLICY_SYNC: '/rest/v1/policy/async',
    POLICY_CALCULATE_V2: '/rest/v2/policy/calculate',
    POLICY_CALCULATE_ADVICE_V2: '/rest/v2/policy/calculateForAdvise',
    POLICY_ILLUSTRATE_V2: '/rest/v2/policy/illustration',
    POLICY_CALCULATE_V1: '/rest/v1/policy/calculate',
    POLICY_APPLY_V2: '/rest/v2/policy/apply',
    POLICY_UPLOAD_V2: '/rest/v2/policy/uploadDocument',
    POLICY_APPLY_V1: '/rest/v1/policy/apply',
    POLICY_UNDERWRITE_V2: '/rest/v2/policy/underwrite',
    POLICY_UNDERWRITE_V1: '/rest/v1/policy/underwrite',
    POLICY_AUTH_QUERY_V2: '/rest/v2/policy/payment/auth/query',
    POLICY_AUTH_APPLY_V2: '/rest/v2/policy/payment/auth/apply',
    POLICY_AUTH_CONFIRM_V2: '/rest/v2/policy/payment/auth/confirm',
    POLICY_AUTH_REAPPLY_V2: '/rest/v2/policy/payment/auth/reapply',
    POLICY_ISSUE_V2: '/rest/v2/policy/issue',
    POLICY_ISSUE_V1: '/rest/v1/policy/issue',
    POLICY_WITHDRAW_V2: '/rest/v2/policy/withdraw',
    POLICY_REQUOTE_V2: '/rest/v2/policy/requote',
    POLICY_RECEIPT_V2: '/rest/v2/policy/receipt',
    POLICY_SUBMIT_ORDER_V2: '/rest/v2/policy/submitOrder',
    POLICY_SUBMIT_ORDER_2_V2: '/rest/v2/policy/submitOrder2',
    POLICY_SUBMIT_POLICY_V2: '/rest/v2/policy/submitPolicy',
    POLICY_SUBMIT_POLICY_ASYNC_V2: '/rest/v2/policy/asyncSubmitPolicy',
    ABANDON_SIGNATURE_IDS: '/rest/v1/policy/signature/setTimeout',
    LOAD_CA_SIGNATURES: '/rest/v2/ca/loadBySignedDocIds',
    CREATE_SIGNATURE: '/rest/v1/policy/signature/create',
    UPDATE_SIGNATURE: '/rest/v1/policy/signature/update',
    REGISTER_SIGNATURE: '/rest/v1/policy/signature/register',
    CHECK_SIGNATURE: '/rest/v1/policy/signature/checkEffective/',
    SEND_VALIDATION_CODE: '/rest/v1/policy/signature/sendCode',
    SEND_COMMON_SMS_CODE: '/rest/v1/sms/sendValidateCode/',
	SEND_SMS_VALIDATE_CODE: '/rest/v1/sms/sendSmsValidateCode',
    SEND_VENDOR_COMMON_SMS_CODE: '/rest/v1/sms/sendValidateCodeForVendor/',
    VALIDATE_VALIDATION_CODE: '/rest/v1/policy/signature/validate',
    VALIDATE_COMMON_SMS_CODE: '/rest/v1/sms/codeValidation/',
    VALIDATE_COMPLETE_SIGNATURE: '/rest/v1/policy/signature/validateComplete',
    CONFIRM_SIGNATURE: '/rest/v1/policy/signature/confirm',
    CHECK_RECEIPT: '/rest/v2/policy/checkReceipt',
    DOC_GENERATE: '/rest/v1/policy/doc/generate',
    SIGN_PDF: '/rest/v1/ca/sign/signpdf',
    SIGN_PDF_V2: '/rest/v2/ca/sign',
    POLICY_IMAGE_UPLOAD: '/rest/v1/policy/image/upload',
    POLICY_IMAGE_UPLOAD_V2: '/rest/v2/policy/image/upload',
    POLICY_VIDEO_UPLOAD: '/rest/v2/policy/video/upload',
    POLICY_DOCUMENT_UPLOAD: '/rest/v1/policy/doc/upload',
    POLICY_COLLECT_PREMIUM: '/rest/v2/policy/collectPrem',
    CUSTOMER_LIST_CONDITION: '/rest/v1/client/list/condition',
    CUSTOMER_LOAD: '/rest/v1/client/detail',
    POLICY_NOTIFY_LOG_LIST: '/rest/v1/policy/log/list/criteria',
    POLICY_NOTIFY_LOG_LOAD: '/rest/v1/policy/log/find/',
    POLICY_NOTIFY_PROCESS_LOG_RETRY: '/rest/v1/policy/log/process/retry/',
    POLICY_NOTIFY_PROCESS_LOG_LOAD: '/rest/v1/policy/log/find/policy/',
    POLICY_GENERATE_QUOTE_NO: '/rest/v2/policy/getInsurerProposalCode',
    POLICY_CHECK_INSURED: '/rest/v2/policy/checkInsured',
    QUERY_AGENT_INFO: '/rest/v2/policy/queryAgentInfo',
    AIXIN_WX_SIGN: '/rest/v1/sign/aixin/getInsuranceSignUrl',
    POLICY_PROBLEM_LIST: '/rest/v1/policy/problem/list',
    POLICY_PROBLEM_SOLVE: '/rest/v1/policy/problem/solve',
    POLICY_ADDRESS_VALIDITY_V2: '/rest/v2/policy/addressValidity',
    UPLOAD_UPDATE_HISTORY: '/rest/v2/policy/uploadAndUpdateHistory',
    UPDATE_STATUS_AND_HISTORY: '/rest/v2/policy/updateStatusAndHistory',
    CHECK_AGENT_QUALIFICATION: '/rest/v2/policy/checkAgentQualification',
    POLICY_CHECK: '/rest/v1/policyCheck/check',

    STATISTICS_REPORT_GENERATE: '/rest/v1/statistics/generate',

    MOBILE_HOME_PRODUCT_LIST: '/rest/v1/product/list/home',
    PRODUCT_CATEGORY_LIST: '/rest/v1/product/category/list',
    PRODUCT_LIST_BY_KEYWORD: '/rest/v1/product/list/keyword/',
    PRODUCT_LIST_BY_VENDOR_IDS: '/rest/v1/product/list/vendors',
    PRODUCT_LIST_BY_VENDOR_CODE: '/rest/v1/product/list/vendorCode',
    PRODUCT_LIST_BY_1ST_TAGS: '/rest/v1/product/list/tags',
    PRODUCT_LIST_BY_1ST_TAG_NAMES: '/rest/v1/product/list/tagNames',
    PRODUCT_LIST_BY_3RD_TAGS: '/rest/v1/product/list/tags/3rd',
    PRODUCT_LIST_BY_ADVICE: '/rest/v1/product/list/advice',
    PRODUCT_LOAD_ADDRESS: '/rest/v1/product/loadProductAddress',

    MY_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/status',
    MY_POLICY_LIST_BY_CRITERIA: '/rest/v1/policy/list/my/criteria',
    MY_CUSTOMER_LIST: '/rest/v1/client/list',
    MY_CUSTOMER_LIST_YDL: '/rest/v1/client/list/fromYDL',
    SALES_AGENT_LIST: '/rest/tenantAccount/center/querySalesmen',
    POLICY_LIST_BY_MY_CUSTOMER: '/rest/v1/client/policy',
    LOAD_CUSTOMER_BY_KEYS: '/rest/v1/client/detailByKeys',
    FAVORITE_PRODUCT_LIST: '/rest/v1/product/favorite/list',
    FAVORITE_PRODUCTS_UNLIKE: '/rest/v1/product/favorite/unlike',
    FAVORITE_PRODUCT_LIKE: '/rest/v1/product/favorite/like/',
    FAVORITE_PRODUCT_UNLIKE: '/rest/v1/product/favorite/unlike/',
    FAVORITE_PRODUCT_IS_LIKED: '/rest/v1/product/favorite/',

    CHECK_AGREEMENT: '/rest/v1/product/checkAgreementProduct/',
    CHECK_AGREEMENT_NO_LOGIN: '/rest/v1/product/checkAgreementProductNoLogin/',

    WECHAT_SIGNATURE: `/rest/v1/wechat/create/signature${asHostBy().wechatPrefixName}`,
    WECHAT_TOC_REGISTER: '/rest/v1/sms/sendTocValidateCode',

    GLUTTON_ASK_TOKEN: '/rest/v1/glutton/ask/token',
    STS_ASK_TOKEN: '/rest/v1/sts/ask/token',

    POLICY_PAYMENT_YIAN: '/rest/v1/payment/yian/pay',
    POLICY_PAYMENT_FOSUN: '/rest/v1/payment/fosun/payUrl',
    POLICY_PAYMENT_FOSUN_DT: '/rest/v1/payment/fosun/dtPayUrl',
    POLICY_PAYMENT_ZHONGAN: '/rest/v1/zhongan/getPaymentUrl',
    POLICY_PAYMENT_DINGCHENG: '/rest/v1/dingcheng/getPaymentUrl',
    POLICY_DECLARATION_ZHONGAN: '/rest/v1/zhongan/getInsuredIntelligentUnderwritingUrl',
    POLICY_PAYMENT_YANGG: '/rest/v1/payment/yangg/payUrl',
    POLICY_PAYMENT_KUNLUN: '/rest/v1/payment/kunlun/payUrl',
    POLICY_PAYMENT_STARRCHINA: '/rest/v1/payment/starrchina/payUrl',
    POLICY_PAYMENT_HEXIE: '/rest/v1/payment/hexie/payUrl',

    POLICY_PAYMENT_QUERY: '/rest/v2/policy/payment/auth/query',
    POLICY_PAYMENT_APPLY: '/rest/v2/policy/payment/auth/apply',
    POLICY_PAYMENT_CONFIRM: '/rest/v2/policy/payment/auth/confirm',

    STATEMENT_AGREEMENT_LIST: '/rest/v1/statement/agreement/query',
    STATEMENT_AGREEMENT_LIST_BY_TENANT_AND_VENDOR: '/rest/v1/statement/agreement/listByCriteria',
    STATEMENT_AGREEMENT_LOAD: '/rest/v1/statement/agreement/load/',
    STATEMENT_AGREEMENT_DELETE: '/rest/v1/statement/agreement/delete/',
    STATEMENT_AGREEMENT_CREATE_OR_UPDATE: '/rest/v1/statement/agreement/createOrSave',
    STATEMENT_AGREEMENT_BY_PRODUCT_ID: '/rest/v1/statement/agreement/querySAlistByConditions',
    STATEMENT_COMMISSION_BY_PRODUCT_ID: '/rest/v1/statement/commission/loadCommissionByConditions',
    STATEMENT_COMMISSION_CREATE_OR_UPDATE: '/rest/v1/statement/commission/createOrSave',
    STATEMENT_BATCH_EXECUTE_BILL: '/rest/v1/statement/batch/executeBill',
    STATEMENT_RESULT_BY_BATCH_ID: '/rest/v1/statement/batch/queryResultByBatchId/',
    STATEMENT_RESULT_LIST: '/rest/v1/statement/batch/queryHistoryResult',
    STATEMENT_BATCH_QUERY_ORGAN: '/rest/v1/statement/batch/queryOrganization',

    OCR_IDCARD_FRONT: '/rest/v1/ocr/idcard',
    OCR_BANKCARD_FRONT: '/rest/v1/ocr/bankcard',
    OCR_HANDWRITING: '/rest/v1/ocr/handwriting',
    OCR_OSS_RECOGNIZE: '/rest/v1/ocr/oss/recognize',
    OCR_IDCARD_COMPANY: '/rest/v2/policy/ocrIdCard',

    MALL_PRODUCT_LIST_HOME: '/rest/v1/mall/product/list/home',
    MALL_PRODUCT_LIST_BY_KEYWORD: '/rest/v1/mall/product/list/keyword',
    MALL_PRODUCT_LIST_BY_PROMOTION: '/rest/v1/mall/product/list/promotion',
    MALL_PRODUCT_LIST_BY_TAGS: '/rest/v1/mall/product/list/tags',
    MALL_PRODUCT_LIST_BY_TAG: '/rest/v1/mall/product/list/tag',
    MALL_PRODUCT_CHECK_AGREEMENT: '/rest/v1/mall/product/checkAgreementProductById',
    MY_MALL_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/status',
    PRODUCT_LOAD_BY_CODE_NO_LOGIN: '/rest/v1/product/loadProductNoLogin/',
    PRODUCT_LOAD_BY_CODE_NO_LOGIN_WITH_TENANTCODE: '/rest/v1/product/loadProductNoLoginWithTenantCode/',
    MALL_CUSTOMER_SIGNIN: '/rest/v1/auth/signinForCus',
    MALL_CUSTOMER_SIGNIN_SMS_CODE: '/rest/v1/sms/sendCodeForCusSignIn',
    MY_MALL_CUSTOMER_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusForCus',
    MY_MALL_2C_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusFor2c',
    MY_MALL_CHANNEL_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusForChannel',

	ID_LIVING_DETECT: '/rest/detect/livingDetect', // 活体检测
    POLICE_VERIFY_WITHOUT_FACE: '/rest/detect/idCardDetect', // 二要素 身份认证(身份证+姓名)
	IDENTITY_NAME_FACE_VERIFY: '/rest/detect/faceDetect', // 三要素 身份认证(身份证+姓名+图片)

    MALL_POLICY_SHARE_SMS_CODE: '/rest/v1/sms/sendCodeForCusShare/',
    MALL_POLICY_SHARE_SMS_CODE_CHECK: '/rest/v1/sms/checkValidateCodeForMall/',

    IDENTITY_CERTIFICATION_LIST: '/rest/tenant/identity/certification/record/list',
    IDENTITY_CERTIFICATION_EXCEL_EXPORT: '/rest/tenant/identity/certification/record/export',

    WEIXIN_GET_OPEN_ID: `/rest/v1/weixin/getWxUser${asHostBy().wechatPrefixName}`,
    LOAD_ACCOUNT_BY_OPEN_ID: '/rest/v1/account/getAccountByOpenId/',
    LOAD_WECHATACCOUNT_BY_ACCOUNTNAME: `/rest/v1/account/getAccountByAccoutName${asHostBy().wechatPrefixName}`,
    TOC_UPDATE_ACCOUNT_INFO: '/rest/v1/auth/updateAccountInfo',
    TOC_UNBOUND_WX_ACCOUNT: '/rest/v1/weixin/unBoundWXAccount',

    BASEINFO_AREA: '/rest/v1/cpcs/baseinfo/list/area/{vendorCode}/{sourceType}/{version}',
    BASEINFO_JOB: '/rest/v1/cpcs/baseinfo/list/job/{vendorCode}/{sourceType}/{version}',
    BASEINFO_IDTYPE: '/rest/v1/cpcs/baseinfo/list/idType/{vendorCode}',
    BASEINFO_MARRIAGE: '/rest/v1/cpcs/baseinfo/list/marriage/{vendorCode}',
    BASEINFO_NATIONALITY: '/rest/v1/cpcs/baseinfo/list/nationality/{vendorCode}',
    BASEINFO_BANK: '/rest/v1/cpcs/baseinfo/list/bank/{vendorCode}/{sourceType}/{version}',
    BASEINFO_EDUCATION: '/rest/v1/cpcs/baseinfo/list/education/{vendorCode}',
    BASEINFO_COUNTRY: '/rest/v1/cpcs/baseinfo/list/country/{vendorCode}',

    BASEINFO_UI_DRIVE_PARAMS: '/rest/v1/product/profile/load?productCode={productCode}',

    MALL_APPOINT_CREATE_PROSPECT: '/rest/v1/client/createProspect',

    IMAGE_AUDIT_INFO_BY_ID: '/rest/v1/image/audit/info/',
    IMAGE_AUDIT_EXAMINE: '/rest/v1/image/audit/examine',
    AUDIT_QUERY_LIST: '/rest/v1/image/audit/list/criteria',
    AUDIT_CREATE: '/rest/v1/image/audit/create',
    QUERY_AUDIT_RESULT: '/rest/v1/image/audit/getImageAuditStatusByNo/',
    AUDIT_APPLY: '/rest/v1/image/audit/apply/',
    AUDIT_DELETE: '/rest/v1/image/audit/deleteImageAuditByNo/',

    QUERY_COLLECT_STATUS: '/rest/v2/policy/queryCollectStatus/',
    CHANGE_BANK_INFO: '/rest/v2/policy/changeBankInfo',
    CHANGE_QUERY_SOLVENCY: '/rest/v2/changc/querySolvency',
    // 人脸识别相关接口
    FACE_RECOGNIZE_RESULT: '/rest/v2/policy/identity/certification/queryPassedList',
    FACE_RECOGNIZE_RESETALL: '/rest/v2/policy/identity/certification/resetByType',
    FXBDX_POLICY_QUERY_OMNIPOTENT_ACCOUNT: '/rest/v1/fxbdx/policy/queryOmnipotentAccount',
    POLICY_DECLARATION_JDALLIANZ: '/rest/v2/eplbks/persion/jdallianz/getInsuredIntelligentUnderwritingUrl',
    VERIFY_PERSONAL_INFO: '/rest/v1/verification/verifyPersonalInfo',

    DR_GET_QRCODE_IMG: '/rest/v2/policy/integration/getAgentQRImageBase64?quotationNo={quotationNo}',
    DR_CHECK_NEED: '/rest/v2/policy/integration/checkNeedDoubleRecord?quotationNo={quotationNo}',
    DR_INVALID: '/rest/v2/policy/integration/invalidDoubleRecord?quotationNo={quotationNo}',
    DR_QUERY_STATUS: '/rest/v2/policy/integration/queryDoubleRecordStatus?quotationNo={quotationNo}',
    DR_SAVE_DOCS: '/rest/v1/document/saveDoubleRecordDocumentURL',
    POLICY_VALIDATE_DATA: '/rest/v2/policy/integration/doubleRecord/validateRule',
    OFFLINE_PAY: '/rest/v2/policy/offlinePay',
    BASE64FACEIMAGE: '/rest/v2/policy/identity/certification/getBase64FaceImage/',
    DR_START_MISSION: '/rest/v2/policy/integration/startDoubleRecord?quotationNo={quotationNo}',
	DR_PUSH_SIGN_STATUS: '/rest/v2/policy/integration/pushDoubleRecordSignatureStatus?quotationNo={quotationNo}',
    ACCOUNT_MAPPING_CURRENT: '/rest/v1/accountMapping/current',
    
	COMPANY_DOUBLE_RECORD: '/rest/v2/policy/doubleRecord',
	// 预览与生成CA接口列表
	GET_PREVIEW_HTML: '/rest/v2/document/previewHtml',//1、查看渲染后的WEB
	GET_PREVIEW_PDF: '/rest/v2/document/previewPdf',//2、查看渲染后的PDF
    GET_PREVIEW_PDFURL: '/rest/v2/document/getPreviewPdfUrl',//3、获取预览的PDF OSS Identity
    GET_PREVIEW_PDFURL_AGILITY: '/rest/policy/enhance/document/preview',//itext快速方式生成pdf
    GET_PREVIEW_PDFURL_AGILITY_PDF: '/rest/policy/enhance/document/previewCommonPDF', // pdf url 预览
    SIGN_PDF_AGILITY: '/rest/policy/enhance/document/singleCertAuth',// itext快速CA
    SIGN_PDF_AGILITY_PDF: '/rest/policy/enhance/document/singleCertAuthCommonPDF', // pdf url ca
    SIGN_PDF_AGILITY_BATCH: '/rest/policy/enhance/document/batchCertAuth',// itext快速CA batch
	CA_SIGN: '/rest/v2/document/signature/sign',//V2版本的CA接口
    CA_SIGNINBATCH: '/rest/v2/document/signature/signInBatch',//V2版本的批量CA接口

    GET_PAYURL: '/rest/v1/kunlun/payUrl',//获取外联收银台地址
    GET_RECEIPT_URL: '/rest/v1/kunlun/getReceiptUrl',//获取外联回执地址
    GET_ISSUES_QUERYLIST: '/rest/policy/issue/queryIssues?policyId={policyId}',
    REPLY_ISSUE_ITEM: '/rest/v1/kunlun/replyPolicyIssue?issueId={issueId}',

    RE_INSURE_POLICY: '/rest/v2/policy/copyPolicy',
    GET_BAR_CODE_IMAGEBASE64: '/rest/qr/getBarCodeImageBase64',//获取条形码
    LIAN_CHECKOUT_NEED_DOUBLERECORD: '/rest/v1/lian/lianCheckNeedDoubleRecord',
    LIAN_GENERATE_PDF: '/rest/v1/lian/lianGeneratePdf',//利安生成客户告知书pdf
    ACCOUNT_PROFILE: '/rest/v1/account/profile',
    DR_DOCUMENT_SIGN: '/rest/v2/policy/integration/isRecording',
    // 查询养老需求及风险承受能力测评
    ELDERLY_CARE_QUESTIONNAIRE_QUERY: '/rest/v1/cll/question',
    //可回执保单列表查询
    CHANGE_QUERY_RECEIPT: '/rest/v2/policy/queryReceipt',
    CHANGE_QUESTIONNAIRE: '/rest/v2/changc/questionnaire',
    POLICY_RECEIPT_KUNLUN: '/rest/v1/kunlun/getReceiptUrl?policyNo={policyNo}',
    FACE_CHECK_INSURED: '/rest/v2/policy/checkInsured',//让后端去请求人脸识别
    CANCEL_POLICY_NO: '/rest/v2/policy/deleteThirdPolicy',//删除订单接口 deleteThirdPolicy
    CHANGE_TO_OFFLINEPAY: '/rest/v2/policy/offlinePay',
    GIVE_UP_POLICY: '/rest/v2/policy/giveUpPolicy',
    DOWNLOAD_EPOLICY: '/rest/v2/policy/downloadEPolicy',

    POLICY_QUERY_ISSUES: '/rest/policy/issue/queryIssues',
    POLICY_ISSUE_UPDATE: '/rest/policy/issue/update',
    POLICY_ISSUE_LOAD: '/rest/policy/issue/queryIssueById',
    POLICY_ISSUE_LOAD_BY_NO: '/rest/policy/issue/queryIssue',
    POLICY_ISSUE_REPLY: '/rest/policy/issue/reply',
    LIAN_DOUBLE_RECORD_UPLOAD_DOCS: '/rest/v1/lian/doubleRecord/uploadDocs',
    LIAN_DOUBLE_RECORD_QUERY_QRCODE: '/rest/v1/lian/doubleRecord/queryQRCode',

    BJLIFE_DECLA_APPLY:'/rest/v2/bjlife/declarationApply?role={role}',//北京人寿智能核保接口
    BJLIFE_QUESTION: '/rest/v2/bjlife/question',
    CANCEL_POLICY: '/rest/v2/policy/cancel ',
    ACCESS_TOKEN:'/rest/v1/tenant/auth/refreshAccessToken',
    CHANGC_RELATED_POLICY: '/rest/v2/changc/relatedPolicy',//长城是否首保查询
    ZHONGH_GET_SIMILAR_CUSTOMER: '/rest/v1/zhongh/getSimilarCustomer/',//中荷相似客户判断
	RECORD_LOG_NOTIFY: '/rest/v1/policy/record/logAndNotify',

    GET_YIXIN_ACCESS_TOKEN: '/rest/v1/zrbx/getAccessToken',

    QUERY_ASSOCIATE_POLICY: '/rest/v2/eplbks/fudsm/policy/queryAssociatedPolicy',
    ASSOCIATE_POLICY: '/rest/v2/eplbks/fudsm/policy/relatedPolicy',
    UPLOAD_AND_COLLECT_POLICY: '/rest/v2/policy/uploadAndCollect/:policyId',
    SEND_VALIDATE_CODE: '/rest/v1/sms/commonSendValidateCode',

    AIXIN_QUESTION: '/rest/v1/aixin/questionnaire/url',//爱心风险问卷

    PRE_SUBMIT_CHECK: '/rest/v2/policy/preSubmitCheck/{policyId}',

    /** documents接口 */
	DOC_SAVE_DOCUMENT_LIST: '/rest/v1/document/saveDocuments',
	DOC_SAVE_OR_UPDATE_DOCUMENT: '/rest/v1/document/saveOrUpdateDocument',
	DOC_DEL_DOCUMENT: '/rest/v1/document/deleteDocument',
	DOC_DEL_DOCUMENT_BY_ROLE: '/rest/v1/document/deleteDocumentForUserRole',
	DOC_DEL_DOCUMENT_BY_IDS: '/rest/v1/document/deleteDocumentForIds',
	DOC_CLEAR_SIGN_DOCUMENTS: '/rest/v1/document/clearSignatureDocument',
	DOC_CLEAR_SIGN_DOCUMENTS_BY_DOCTYPES: '/rest/v1/document/clearSignatureForDocumentTypes',
	DOC_UPDATE_DOCS_HOLDER: '/rest/v2/policy/updatePolicyHolderDocuments',
	DOC_UPDATE_DOCS_INSURED_AND_BENEFICARY: '/rest/v2/policy/updateInsuredBeneficiaryDocuments',
    DOC_UPDATE_BENEFICIARY_DOCUMENTS: '/rest/v1/document/saveSameWithPHDocument/{quotationNo}',
	// DOC_GET_DOCUMENTS_LIST_QUOTENO: '/rest/v1/document/listByQuotationNo/{quotationNo}',
	// DOC_DOCUMENTS_LIST_QUOTENO_DOCTYPE: '/rest/v1/document/listByQuotationNoAndDocumentType',
	// DOC_DOCUMENTS_LIST_QUOTENO_DOCTYPES: '/rest/v1/document/listByQuotationNoAndDocumentTypes',
	// DOC_DOCUMENTS_LIST_QUOTENO_ROLETYPE: '/rest/v1/document/listByQuotationNoAndUserRole',
    DOC_COMPRESSION_DOCUMENT: '/rest/v1/document/compressionDocument',

    KUNLUN_QUERY_RISK_GRADE: '/rest/v1/kunlun/queryRiskGrade',
    KUNLUN_QUERY_RISK_QUESTIONNAIRE: '/rest/v1/kunlun/queryRiskQuestionnaire',
    KUNLUN_ASSESS_RISK_GRADE: '/rest/v1/kunlun/assessRiskGrade',

    XINT_H5_GETURL: '/rest/v1/xint/getH5Url/',
    XINT_H5_PAYMENT: '/rest/v1/xint/payment/',
    XINT_H5_ISSUEDETAIL: '/rest/v1/xint/issueDetail/',
    XINT_H5_WITHDRAW: '/rest/v1/xint/withdrawBeforeIssue/',
    XINT_H5_RECEIPT: '/rest/v1/xint/receipt/',

    CALCULATE_QUESTIONNAIRE: '/rest/v2/policy/calculateForQuestionnaire',
    INVALIDATE_BY_SHARE_RECORD_NO:'/rest/share/invalidateByShareRecordNo',
    GET_AGENT_DECLARATIONS:'/rest/v2/changc/getAgentDeclarations',

    //发送电子保单邮件（链接）
    SEND_EMAIL_EPOLICY: '/rest/v1/policy/send/email/e-policy',

    ZHONGYOU_QUERY_RISK_QUESTIONNAIRE: '/rest/v1/zhongyou/questionnaire',

    EMAIL_EPOLICY: '/rest/v1/policy/email/e-policy',

    GET_TENANTNAME_BY_TENANTCODE :'/rest/v1/tenant/loadNameTenantByCode/{TenantCode}',
    FUDSM_QUERY_RISK_QUESTIONNAIRE: '/rest/v2/eplbks/fudsm/policy/questionnaire',
    QUERY_RISK_GRADE: '/rest/v2/policy/queryRiskGrade',

    FAMILY_MEMBER_LIST_ACCOUNT_ID: '/rest/v1/familyMember/list/accountId',

    LOAD_ENDORESMENTLIST_BY_POLICY:'/rest/policy/endorsement/findEndorsement',
	LOAD_SIGNATURE: '/rest/v1/policy/signature/load/',
    MEJ_VERIFY_SMS:'/rest/v1/sms/send',            //MEJ被保人确认短信发送
    LONG_TO_SHORT_URL: '/rest/v1/shorturl/long2short',
    SURRENDER_CALCULATE:'/rest/policy/endorsement/surrenderCalculate',
    SURRENDER_CREATE:'/rest/policy/endorsement/create',
    SURRENDER_SUBMIT:'/rest/policy/endorsement/submitSurrender',
    SEND_SMS_VALIDATE_CODE_TYPE:'/rest/v1/sms/sendSmsValidateCodeType',
    POLICY_CALCULATE_QUOTE_V2: '/rest/v2/policy/quote',
    POLICY_QUERY_DOUBLE_RECORD_STATUS: '/rest/v2/policy/queryDoubleRecordStatus',
} as APIS;
